'use strict';

const SearchRoutes = React.lazy(() => import( /* webpackChunkName: "SearchRoutes" */ '../components/SearchRoutes'));
const SearchSwitch = React.lazy(() => import( /* webpackChunkName: "SearchSwitch" */ '../components/SearchSwitch'));

class HomeSearchContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            type: reactInitData.type || 'planes',
            airports: [],
            routes: [{
                from: {},
                to: {},
                date: '',
                time: '',
                seats: 0,
            }],
        };

        this.handleChangeType = this.handleChangeType.bind(this)
        this.handleChangeRoutes = this.handleChangeRoutes.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
    }

    componentDidMount() {
        this.init();
    }

    init() {
        requestAction.initData().then(response => {
            this.setState({
                airports: response.airports,
            })
        })
    }

    handleChangeType(type) {
        this.setState({type}, () => {
           $('a[href="#m2tab'+(type === 'planes' ? 1 : 2)+'"]').trigger('click')
        });
    }

    handleChangeRoutes(routes) {
        this.setState({routes});
    }

    handleSearch() {
        const {type, routes} = this.state;
        storageSet('reactSearchData', {type, routes})
        ymReachGoal('search')
        window.location = '/search';
    }

    render() {
        const {type, routes, airports} = this.state;
        return <div>
            <div className="main-section__title">
                {tMsg('Поиск лучших цен')}
            </div>
            <React.Suspense>
                <SearchSwitch
                    type={type}
                    onChangeType={this.handleChangeType}
                />
            </React.Suspense> 
            <React.Suspense>
                <SearchRoutes
                    type={type}
                    routes={routes}
                    airports={airports}
                    onChangeRoutes={this.handleChangeRoutes}
                    onSearch={this.handleSearch}
                    requestMode={true}
                />
            </React.Suspense> 
        </div>
    }
}

const domContainer = document.querySelector('#reactHomeSearchContainer');
const root = ReactDOM.createRoot(domContainer);
root.render(React.createElement(HomeSearchContainer));
